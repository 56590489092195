<template>
	<div>
		<s-crud
			title="Cerrar Lote Calibrado"
			no-full
			:filter="filter"
			:config="config"
			search-input
			:mobile="1024"
			ref="gridcloseLotCalibrated"
			height="auto"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot:filter>
				<v-container>
					<v-row style="margin-left: 5px" justify="center">
						<v-col cols="6" md="4" lg="4">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>
						<v-col cols="6" md="4" lg="4">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>

						<v-col cols="12" class="s-col-form" xs="4" sm="4" md="4">
							<s-switch label="Lotes Cerrados" v-model="filter.filterClose"></s-switch>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:LcsAmountSupplyReal="{row}">
				<v-chip
					small
					:color="'info'"	
				>
					{{row.LcsAmountSupplyReal}}
				</v-chip>
			</template>

			<template v-slot:Options="{ row }">
				<div style="display: flex;">
					<v-btn
						v-if="filter.filterClose==0"
						x-small
						:color="'warning'"
						:height="35"
						rounded
						shaped
						style="border-radius: 13px; margin: 10px 10px 10px 10px;"
						@click="terminatedLot(row, 1)"
						>Cerrar Lote
					</v-btn>
				</div>

				<div style="display: flex;" v-if="$fun.isAdmin()">
					<v-btn
						v-if="filter.filterClose==1"
						small
						:color="'error'"
						rounded
						shaped
						style="border-radius: 13px; margin: 10px 10px 10px 10px;"
						@click="terminatedLot(row, 2)"
						>Habilitar
					</v-btn>
				</div>
			</template>

			<template v-slot:RcfID="{row}">
				 {{row.RcfID + "-" + row.RcfCode}}
			</template>
			<!-- <template v-slot:Check="{ row }">
				<div style="display: flex;">
					<v-btn
						v-if="filter.filterClose==1"
						small
						:color="'error'"
						rounded
						shaped
						style="border-radius: 13px; margin: 10px 10px 10px 10px;"
						@click="terminatedLot(row, 2)"
						>Habilitar
					</v-btn>
				</div>
			</template> -->
		</s-crud>
	</div>
</template>

<script>
	import _sLotProdCalibratedSupplyService from "../../../services/FreshProduction/PrfLotProdCalibratedSupplyService";

	export default {
		data () {
			return {
				filter: {
					cDateInitial: null,
					cDateFin: null,
					filterAll: 2,
					filterClose: 0,
				},
				config: {
					service: _sLotProdCalibratedSupplyService,
					model: {
						RcfID: "ID",
						Options: "",
						LcsAmountSupplyReal: "",
						Check: ""
					},

					headers:   
						[
							{ text: "Opciones", value: "Options", width: 100 },
							/* { text: "Habilitar", value: "Check", width: 50 }, */
							{ text: "Nro. Lote.", value: "RcfID", width: 100 },
							{
								text: "Cant. Jabas Recep.",
								value: "AmountJabasWeigh",
								width: 100
							},
							{
								text: "Cant. Jabas Abastecida",
								value: "LcsAmountSupplyReal",
								width: 100
							},
							{
								text: "Cant. Jabas Calibradas.",
								value: "LdhQuantityJaba",
								width: 100
							},
							

							{ text: "Tipo Servicio", value: "TypeServiceName", width: 100 },
							{ text: "Productor", value: "PrdCardName", width: 360 },
							{ text: "Fundo", value: "FagName", width: 260 },
							{ text: "Lote", value: "FltName", width: 100 },
							{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
							{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
							{ text: "Variedad", value: "VrtName", width: 160 },
							{ text: "Procedencia", value: "TypeOriginName", width: 160 }
						] 
				}
			}
		},
		methods: {
			rowSelected(item){
				console.log(item);
			},

			terminatedLot(item, Flag) {


				var message = ""
				if(Flag == 1){
					message = "¿ Desea Cerrar Lote Calibrado ?, Recuerde que una vez cerrado el Lote, ya no puede realizar el armado de Parihuelas."
				}else{
					message = "¿ Seguro de Habilita Lote Producción ?"
				}
				this.$fun
					.alert(message, "question")
					.then(val => {
						if (val.value) {
							
							_sLotProdCalibratedSupplyService
								.closeLot({RcfID: item.RcfID, Flag: Flag, UsrCreateID: this.$fun.getUserID()}, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Cerrado correctamente",
											"success"
										);
										this.$refs.gridcloseLotCalibrated.refresh();
									}
								});
						}
					});

				console.log("Guardar", item);
			}
		}
	};
</script>
